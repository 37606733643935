  .about-text{display: block}
  .about-text1{display: none}
  .about-text2{display: block}
  .about-text21{display: none}

  @media screen and (min-width: 600px) {
    .about-text1  { display: block;}
    .about-text  { display: none; }
    .about-text2{display: none}
    .about-text21{display: block}
  }

