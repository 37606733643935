  .text1{display: block}
  .text11{display: none}

  .wrapper1 {
    background-color: white;
    display: grid;
    text-align: left;
    justify-items: center;
    grid-template-columns: 33% 67%;
  }

  @media screen and (min-width: 600px) {
    .text11  { display: block;}
    .text1  { display: none; }
  }

