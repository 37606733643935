.menu_icon {
  visibility: hidden;
}

@media screen and (max-width: 600px) {
  .menuButtons{
    display: none;
  }
  .menu_icon {
    visibility: visible;
  }
}

.logo {
  text-align: left;
  background: linear-gradient(45deg, #424242, #757575);
  -webkit-background-clip: text;
  color: transparent;
  text-transform: uppercase;
  letter-spacing: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}