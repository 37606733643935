
  .wrapper3 {
    background-color: #212121;
    display: grid;
    text-align: center;
    justify-items: center;
    grid-template-columns: 1fr 2fr;
  }
  .box3 {display: inline-grid;
    text-align: center;
    justify-items: center;
  }
