
  .wrapper {
    background-color: #ffd54f;
    display: grid;
    text-align: center;
    justify-items: center;
    grid-template-columns: 33% 34% 33%;
  }

  .h_line {
    color: black;
    border: none;
    background-color: black;
    width: 70%;
    height: 2px;
  }
  .box41{
    display: none;
  }

  .box1 {
    padding-bottom: 5%;
  }
  .box21 {
    display: none;
  }

  .box2 {
    padding-bottom: 5%;
  }

  @media screen and (min-width: 600px) {
    .box21 {
      display: block;
      padding-bottom: 5%;
    }

    .box2{
      display: none;
    }

    .box41{
      display: block;
    }

    .box4{
      display: none;
    }
  }
